import { graphql, Link } from 'gatsby'
import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

const ContactPage = ({
  data: {
    site
  },
}) => {
  
  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <div className="contact-form two-grids -contact px-3 px-md-0">
        <div className="post-thumbnail" style={{backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`, marginBottom: 0}}>
          <h1 className="post-title">odezwij się!</h1>
          <p>z nami prawko to tylko kwestia czasu &rarr;</p>
        </div>
        <div>
          <form name="contact" className="form-container" method="post" action="/success" data-netlify="true" netlify-honeypot="this-isnt-the-field-you-are-looking-for">
            <input type="hidden" name="form-name" value="contact" />
            <div className="invisible-field">
              <label>Hey droid!
                <input name="this-isnt-the-field-you-are-looking-for" />
              </label>
            </div>
            <input type="hidden" name="form-name" value="contact" />
            <div>
              <label htmlFor="name">imię i nazwisko</label>
              <input type="text" name="name" id="name" required/>
            </div>
            <div>
              <label htmlFor="pesel">pesel</label>
              <input type="tel" pattern="[0-9]{11}" name="pesel" id="pesel" required/>
            </div>
            <div>
              <label htmlFor="pkk">pkk (opcjonalnie)</label>
              <input type="tel" name="pkk" id="pkk"/>
            </div>
            <div>
              <label htmlFor="sender">email</label>
              <input type="email" name="sender" id="sender" required/>
            </div>
            <div>
              <label htmlFor="telephone">telefon</label>
              <input type="tel" name="telephone" id="telephone" pattern="[0-9]{9,11}" required/>
            </div>
            <div>
              <label htmlFor="details">uwagi</label>
              <textarea name="details" id="details"></textarea>
            </div>
            <div className="rodo-checkbox">
              <input type="checkbox" name="privacy" id="privacy" className="form-check-input" required></input>
              <label htmlFor="privacy" className="form-check-label">
                <p>(Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu dla potrzeb niezbędnych do realizacji procesu zgłoszenia na kurs).</p>
                <p><Link to="/tos">[Polityka prywatności]</Link></p>
              </label>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <button type="submit" className="button -primary submit-button">wyślij</button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`